// Typography

// rob

// 10px
.font-rob-10-reg {
  font-family: "roboto";
  font-size: 10px;
  font-weight: 400;
}

.font-rob-10-medium {
  font-family: "roboto";
  font-size: 10px;
  font-weight: 500;
}

.font-rob-10-bold {
  font-family: "roboto";
  font-size: 10px;
  font-weight: 700;
}

// 12px
.font-rob-12-reg {
  font-family: "roboto";
  font-size: 12px;
  font-weight: 400;
}

.font-rob-12-medium {
  font-family: "roboto";
  font-size: 12px;
  font-weight: 500;
}

.font-rob-12-bold {
  font-family: "roboto";
  font-size: 12px;
  font-weight: 700;
}

// 14px
.font-rob-14-reg {
  font-family: "roboto";
  font-size: 14px;
  font-weight: 400;
}

.font-rob-14-medium {
  font-family: "roboto";
  font-size: 14px;
  font-weight: 500;
}

.font-rob-14-bold {
  font-family: "roboto";
  font-size: 14px;
  font-weight: 700;
}

// 16px
.font-rob-16-reg {
  font-family: "roboto";
  font-size: 16px !important;
  font-weight: 400;
}

.font-rob-16-medium {
  font-family: "roboto";
  font-size: 16px;
  font-weight: 500;
}

.font-rob-16-bold {
  font-family: "roboto";
  font-size: 16px;
  font-weight: 700;
}

// 18px
.font-rob-18-reg {
  font-family: "roboto";
  font-size: 18px !important;
  font-weight: 400;
}

.font-rob-18-medium {
  font-family: "roboto";
  font-size: 18px;
  font-weight: 500;
}

.font-rob-18-bold {
  font-family: "roboto";
  font-size: 18px;
  font-weight: 700;
}

// 20px
.font-rob-20-light {
  font-family: "roboto";
  font-size: 20px !important;
  font-weight: 300;
}

.font-rob-20-reg {
  font-family: "roboto";
  font-size: 20px !important;
  font-weight: 400;
}

.font-rob-20-medium {
  font-family: "roboto";
  font-size: 20px;
  font-weight: 500;
}

.font-rob-20-bold {
  font-family: "roboto";
  font-size: 20px;
  font-weight: 700;
}

// 22px
.font-rob-20-light {
  font-family: "roboto";
  font-size: 22px !important;
  font-weight: 300;
}

.font-rob-22-reg {
  font-family: "roboto";
  font-size: 22px !important;
  font-weight: 400;
}

.font-rob-22-medium {
  font-family: "roboto";
  font-size: 22px;
  font-weight: 500;
}

.font-rob-22-bold {
  font-family: "roboto";
  font-size: 22px;
  font-weight: 700;
}

// 24px
.font-rob-24-reg {
  font-family: "roboto";
  font-size: 24px;
  font-weight: 400;
}

.font-rob-24-medium {
  font-family: "roboto";
  font-size: 24px;
  font-weight: 500;
}

.font-rob-24-bold {
  font-family: "roboto";
  font-size: 24px;
  font-weight: 700;
}

// 26px
.font-rob-26-reg {
  font-family: "roboto";
  font-size: 26px !important;
  font-weight: 400;
}

.font-rob-26-medium {
  font-family: "roboto";
  font-size: 26px !important;
  font-weight: 500;
}

.font-rob-26-bold {
  font-family: "roboto";
  font-size: 26px;
  font-weight: 700;
}

// 28px
.font-rob-28-reg {
  font-family: "roboto";
  font-size: 26px !important;
  font-weight: 400;
}

.font-rob-28-medium {
  font-family: "roboto";
  font-size: 26px !important;
  font-weight: 500;
}

.font-rob-28-bold {
  font-family: "roboto";
  font-size: 26px;
  font-weight: 700;
}

// 30
.font-rob-30-reg {
  font-family: "roboto";
  font-size: 30px;
  font-weight: 400;
}

.font-rob-30-medium {
  font-family: "roboto";
  font-size: 30px !important;
  font-weight: 500;
}

.font-rob-30-bold {
  font-family: "roboto";
  font-size: 30px;
  font-weight: 700;
}

// pop

// 8px
.font-pop-8-medium {
  font-size: 8px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

// 10px
.font-pop-10-light {
  font-family: "Poppins", sans-serif;
  font-size: 10px;
  font-weight: 300;
}

.font-pop-10-reg {
  font-family: "Poppins", sans-serif;
  font-size: 10px;
  font-weight: 400;
}

.font-pop-10-medium {
  font-size: 10px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.font-pop-10-semi-bold {
  font-size: 10px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

.font-pop-10-bold {
  font-size: 10px;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
}

// 12px
.font-pop-12-light {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-weight: 300;
}

.font-pop-12-reg {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-weight: 400;
}

.font-pop-12-medium {
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.font-pop-12-semi-bold {
  font-size: 10px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

.font-pop-12-bold {
  font-size: 10px;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
}

// 14px
.font-pop-14-light {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 300;
}

.font-pop-14-reg {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.font-pop-14-medium {
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.font-pop-14-semi-bold {
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

.font-pop-14-bold {
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
}
// 16px
.font-pop-16-light {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 300;
}

.font-pop-16-reg {
  font-family: "Poppins", sans-serif !important;
  font-size: 16px !important;
  font-weight: 400;
}

.font-pop-16-medium {
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.font-pop-16-semi-bold {
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

.font-pop-16-bold {
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
}

// 18px
.font-pop-18-light {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 300;
}

.font-pop-18-reg {
  font-family: "Poppins", sans-serif;
  font-size: 18px !important;
  font-weight: 400;
}

.font-pop-18-medium {
  font-size: 18px !important;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.font-pop-18-semi-bold {
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

.font-pop-18-bold {
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
}

// 20px
.font-pop-20-light {
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: 300;
}

.font-pop-20-reg {
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: 400;
}

.font-pop-20-medium {
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.font-pop-20-semi-bold {
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

.font-pop-20-bold {
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
}

// 22px
.font-pop-22-light {
  font-family: "Poppins", sans-serif;
  font-size: 22px;
  font-weight: 300;
}

.font-pop-22-reg {
  font-family: "Poppins", sans-serif;
  font-size: 22px;
  font-weight: 400;
}

.font-pop-22-medium {
  font-size: 22px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.font-pop-22-semi-bold {
  font-size: 22px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

.font-pop-22-bold {
  font-size: 22px;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
}

// 24px
.font-pop-24-light {
  font-family: "Poppins", sans-serif;
  font-size: 24px;
  font-weight: 300;
}

.font-pop-24-reg {
  font-family: "Poppins", sans-serif;
  font-size: 24px;
  font-weight: 400;
}

.font-pop-24-medium {
  font-size: 24px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.font-pop-24-semi-bold {
  font-size: 24px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

.font-pop-24-bold {
  font-size: 24px;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
}

// 26px
.font-pop-26-light {
  font-family: "Poppins", sans-serif;
  font-size: 26px;
  font-weight: 300;
}

.font-pop-26-reg {
  font-family: "Poppins", sans-serif;
  font-size: 26px;
  font-weight: 400;
}

.font-pop-26-medium {
  font-size: 26px !important;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.font-pop-26-semi-bold {
  font-size: 26px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

.font-pop-26-bold {
  font-size: 26px;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
}

// 28px
.font-pop-28-light {
  font-family: "Poppins", sans-serif;
  font-size: 28px;
  font-weight: 300;
}

.font-pop-28-reg {
  font-family: "Poppins", sans-serif;
  font-size: 28px;
  font-weight: 400;
}

.font-pop-28-medium {
  font-size: 28px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.font-pop-28-semi-bold {
  font-size: 28px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

.font-pop-28-bold {
  font-size: 28px;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
}

// 30px
.font-pop-30-light {
  font-family: "Poppins", sans-serif !important;
  font-size: 30px !important;
  font-weight: 300;
}

.font-pop-30-reg {
  font-family: "Poppins", sans-serif;
  font-size: 30px;
  font-weight: 400;
}

.font-pop-30-medium {
  font-size: 30px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.font-pop-30-semi-bold {
  font-size: 30px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

.font-pop-30-bold {
  font-size: 30px;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
}

//34px
.font-pop-34-light {
  font-family: "Poppins", sans-serif !important;
  font-size: 34px !important;
  font-weight: 300;
}
.font-pop-34-reg {
  font-family: "Poppins", sans-serif;
  font-size: 34px;
  font-weight: 400;
}
.font-pop-34-medium {
  font-size: 34px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}
.font-pop-34-semi-bold {
  font-size: 34px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}
.font-pop-34-bold {
  font-size: 34px;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
}

// 36px
.font-pop-36-light {
  font-family: "Poppins", sans-serif;
  font-size: 36px;
  font-weight: 300;
}

.font-pop-36-reg {
  font-family: "Poppins", sans-serif;
  font-size: 36px;
  font-weight: 400;
}

.font-pop-36-medium {
  font-size: 36px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.font-pop-36-semi-bold {
  font-size: 36px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

.font-pop-36-bold {
  font-size: 36px;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
}

// 38px
.font-pop-38-light {
  font-family: "Poppins", sans-serif;
  font-size: 38px;
  font-weight: 300;
}

.font-pop-38-reg {
  font-family: "Poppins", sans-serif;
  font-size: 38px;
  font-weight: 400;
}

.font-pop-38-medium {
  font-size: 38px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.font-pop-38-semi-bold {
  font-size: 38px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

.font-pop-38-bold {
  font-size: 38px;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
}

// 40px
.font-pop-40-light {
  font-family: "Poppins", sans-serif;
  font-size: 40px;
  font-weight: 300;
}

.font-pop-40-reg {
  font-family: "Poppins", sans-serif;
  font-size: 40px;
  font-weight: 400;
}

.font-pop-40-medium {
  font-size: 40px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.font-pop-40-semi-bold {
  font-size: 40px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

.font-pop-40-bold {
  font-size: 40px;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
}

// 44px
.font-pop-44-light {
  font-family: "Poppins", sans-serif;
  font-size: 44px;
  font-weight: 300;
}

.font-pop-44-reg {
  font-family: "Poppins", sans-serif;
  font-size: 44px;
  font-weight: 400;
}

.font-pop-44-medium {
  font-size: 44px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.font-pop-44-semi-bold {
  font-size: 44px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

.font-pop-44-bold {
  font-size: 44px;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
}

// 50px
.font-pop-50-light {
  font-family: "Poppins", sans-serif;
  font-size: 50px;
  font-weight: 300;
}

.font-pop-50-reg {
  font-family: "Poppins", sans-serif;
  font-size: 50px;
  font-weight: 400;
}

.font-pop-50-medium {
  font-size: 50px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.font-pop-50-semi-bold {
  font-size: 50px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

.font-pop-50-bold {
  font-size: 50px;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
}

// 80px
.font-pop-80-light {
  font-family: "Poppins", sans-serif;
  font-size: 80px;
  font-weight: 300;
}

.font-pop-80-reg {
  font-family: "Poppins", sans-serif;
  font-size: 80px;
  font-weight: 400;
}

.font-pop-80-medium {
  font-size: 70px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.font-pop-80-semi-bold {
  font-size: 70px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

.font-pop-80-bold {
  font-size: 80px;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
}
