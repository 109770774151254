/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import "~primeicons/primeicons.css";
@import "~primeflex/primeflex.css";
@import "~primeng/resources/themes/fluent-light/theme.css";
@import "~primeng/resources/primeng.min.css";
@import '~ngx-sharebuttons/themes/material/material-dark-theme';
@import '~ngx-sharebuttons/themes/modern/modern-light-theme';
@import '~ngx-sharebuttons/themes/circles/circles-dark-theme';
@import '~@ctrl/ngx-emoji-mart/picker';


@import "./theme/custom.scss";
@import "./theme/typograpgy.scss";

@import url("https://fonts.googleapis.com/css2?family=PT+Sans+Caption:wght@400;700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

@import "~jsgantt-improved/dist/jsgantt.css";
@import '~ngx-org-chart/_theming.scss';

.auth-alert {
  --background: #fbece9 !important;
  color: red;
  --border-radius: 5px !important;
  h2 {
    --color: crimson !important;
  }
}

html, body {
  min-height: 100%;
}

html {
  scroll-behavior: smooth;
}

.modal-wrapper {
  --height: 60% !important;
  --width: 100% !important;
  max-height: 250px !important;
  // --top: 20% !important;
  padding: 10% !important;
  bottom: 0 !important;
  position: absolute;
  display: block;
  border-top-left-radius: 40px !important;
  border-top-right-radius: 40px !important;
  background: white !important;
}

 .my-custom-modal-css4 {
  background: greenyellow !important;
}
.my-custom-modal-css4 {
  // background-color: yellow !important;
  bottom: 80px !important;
  // --height: fit-content !important;
  // --max-width: 347px !important;
  .modal-wrapper {
    --height: 100% !important;
    max-height: 100% !important;
    background: #FFFFFF 0% 0% no-repeat padding-box !important;
    border-radius: 0px !important;
  }
}

  .landing-page-pop-up {
  // background: greenyellow !important;
  // background-color: transparent !important;
}
.landing-page-pop-up {
  // background-color: yellow !important;
  // bottom: 80px !important;
  // --height: fit-content !important;
  // --max-width: 347px !important;
  .modal-wrapper {
    --height: 100% !important;
    max-height: 100% !important;
    background: #FFFFFF 0% 0% no-repeat padding-box !important;
    // background-color: transparent;
    border-radius: 0px !important;
  }
  ion-modal {
    bottom: 0px !important;
  }
}

  .my-custom-modal-css3  {
  // background-color: yellow !important;
   bottom:80px !important;
   --height: fit-content !important;
  .modal-wrapper {
    --height: 65% !important;
  //   top: 69% !important;
  // left: 50% !important;
  }
}



 .my-custom-modal-css2  {
  bottom: 20px !important;
  --height: fit-content !important;
  // background-color: yellow !important;
 // bottom: 0 !important;

  .modal-wrapper {
    --height: 90% !important;
    max-height: 100% !important;
  }
}

.my-custom-modal-center {
  bottom: -20px !important;
  --height: fit-content !important;
  // width: 51% !important;
  // margin: auto !important;
  max-width: 768px;
    margin: auto;
    width: 100%;
  // --max-width: 347px !important;
  .modal-wrapper {
    --height: 90% !important;
    max-height: 100% !important;
  }
}
//   ion-backdrop {
//     height: calc(100vh - 80px);
// }

// ion-modal {
//   bottom: 70px !important;
// }

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

body .toast-msg {
   --width: fit-content !important;
   opacity: 0.95 !important;
   text-align: center;
 }
