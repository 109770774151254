// Custome Css
ion-content {
  --background: transparent;
  // font-family: "Roboto", sans-serif;
}

.color-contrast-lightt {
  color: var(--ion-cc-contrast-light) !important;
}

.color-primary {
  color: var(--ion-cc-primary) !important;
}

.color-primary-tint {
  color: var(--ion-cc-primary-tint) !important;
}

.color-secondary {
  color: var(--ion-cc-secondary) !important;
}

.color-secondary-tint {
  color: var(--ion-cc-secondary-tint) !important;
}

.background-primary {
  background: var(--ion-cc-primary) !important;
}

ion-app {
  background: var(--ion-cc-primary-gradient);
}

circle-progress {
  font-style: italic;
}

ion-item {
  --highlight-height: 0;
}

@media only screen and (min-width: 992px) {
  ion-app {
    background: var(--ion-cc-background-grey) !important;
  }
}

ion-toolbar {
  --background: transparent !important;
  --background-color: transparent !important;
  background: var(--ion-cc-primary) !important;
  background-color: transparent !important;
}

ion-back-button {
  color: var(--ion-cc-contrast-light) !important;
  --color: var(--ion-cc-contrast-light) !important;
}

.ion-back-button-black {
  color: var(--ion-cc-contrast-dark) !important;
}

app-mobile-footer {
  height: 80px;
}

ion-footer {
  padding-top: 15px !important;
}

.footer-md::before {
  background-image: none;
}

.w-90 {
  width: 90%;
}

.text-orange {
  color: var(--ion-cc-item-orange) !important;
}

.text-white {
  color: var(--ion-cc-contrast-light) !important;
}

h1 {
  margin-top: 0 !important;
}

// Css components

// Buttons

ion-button {
  opacity: 1;
  text-transform: none;
  letter-spacing: unset;
}

.footer-btn {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
}

.btn_style_1 {
  --color: var(--ion-cc-contrast-light) !important;
  --background: var(--ion-cc-secondary-tint) !important;
  font-size: 20px !important;
  margin: 15px 0;
  height: 64px;
}

.btn_style_2 {
  --color: var(--ion-cc-contrast-light) !important;
  --background: var(--ion-cc-primary) !important;
  font-size: 20px !important;
  height: 64px;
}

.btn_style_3 {
  --background: var(--ion-cc-contrast-light) !important;
  --color: var(--ion-cc-primary-tint) !important;
  font-size: 20px !important;
  height: 64px;
  width: min-content;
  --box-shadow: none !important;
}

.btn_style_5 {
  --background: var(--ion-cc-tertiary-tint) !important;
  --color: var(--ion-cc-contrast-light) !important;
  font-size: 20px;
  height: 64px;
  width: min-content;
  // --box-shadow: none !important;
}

.clearbutton {
  --color: var(--ion-cc-primary) !important;
}

.apple-btn {
  --background: transparent !important;
  --box-shadow: none !important;

  img {
    width: 25px;
  }
}

.google-btn {
  --background: transparent !important;
  // --color: var(--ion-cc-primary-grey-shade) !important;
  // font-size: 14px !important;
  --box-shadow: none !important;

  img {
    width: 25px;
  }
}

.facebook-btn {
  --background: transparent !important;
  --box-shadow: none !important;

  img {
    width: 25px;
  }
}

.social-btn {
  // margin-top: 30px;

  // Apple btn

  // google btn
  .google-btn {
    background: var(--ion-cc-contrast-light);
    color: var(--ion-cc-primary-grey-shade);
    text-align: left;
    height: 50px;
    margin-top: 6px;

    img {
      padding: 0 15px;
      width: 50px;
      border-right: 1px solid #00000052;
      margin-right: 20px;
    }
  }

  // facebook btn
  .facebook-btn {
    background: var(--ion-cc-contrast-dark);
    color: var(--ion-cc-contrast-light);
    text-align: left;
    height: 50px;
    margin-top: 6px;

    img {
      padding: 0 15px;
      width: 50px;
      border-right: 1px solid var(--ion-cc-primary-grey-tint);
      margin-right: 20px;
    }
  }
}

.a-no-underline {
  text-decoration: none;
}

.watermark {
  position: fixed;
  bottom: 20%;
  right: 4%;
  z-index: 0;
  opacity: 0.15;
}

.watermark-top {
  top: 0px !important;
  bottom: unset !important;
}

.editicon {
  color: var(--ion-cc-primary);
  float: right;
  width: 15pt;
  height: 15pt;
}

// Form Related Css

.input-section {
  ion-item {
    ion-input {
      color: var(--ion-cc-contrast-light);
    }

    ion-icon {
      top: 25px;
      position: relative;
      color: var(--ion-cc-contrast-light);
    }
  }
}

// ion-item {
//   border-bottom: 1px solid #0B58B0;
// }

.error-message {
  color: var(--ion-cc-tertiary-shade);
}

.error-message-tertiary {
  color: var(--ion-cc-tertiary);
}

ion-item {
  --ion-background-color: var(--ion-cc-background-grey);
  --background: transparent !important;
  // --background: white !important;
  --border-color: var(--ion-cc-contrast-light);
  --inner-border-width: 0px;
}

ion-label {
  --color: --ion-cc-contrast-light;
}

.select-style-1 {
  border-bottom: 1.5px solid var(--ion-cc-background-grey);
  padding-bottom: 0;
}

ion-select::part(icon) {
  color: var(--ion-cc-contrast-dark);
  opacity: 1;
}

.pr-0 {
  padding-right: 0 !important;
}

.header-md::after {
  background-image: none !important;
}

.int_tel_error {
  height: 45px;
  // width:100% ;
  border-radius: 4px;
  background: var(--ion-cc-contrast-light);
  border: 2px solid var(--ion-cc-tertiary);
}

.int_tel_signup {
  height: 45px;
  // width: 100% ;
  background: var(--ion-cc-contrast-light);
  border: 1px solid #ccc !important;
  width: 100% !important;
}

input[type="text"].otp {
  height: 40px;
  width: 36px;
  font-size: 16px;
  text-align: center;
  border: 1px solid var(--ion-cc-background-grey);
  border-radius: 4px;
  margin: 4px;
}

.fab_btn {
  --background: var(--ion-cc-secondary-tint);
  --color: var(--ion-cc-contrast-light);
}

.ion-color-primary {
  --ion-color-base: none !important;
}

.center-aligned {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.right-aligned {
  text-align: right;
}

@media screen and (min-width: 992px) {
  .website-design {
    background: var(--ion-cc-contrast-light) !important;
    border-radius: 15px;
    padding-bottom: 20px;
    padding-top: 20px;
    padding-left: 22px;
    padding-right: 20px;
    box-shadow: 0 3px 6px #00000029;
  }

  .web-text-center {
    text-align: center;
  }

  .web-content {
    margin: auto;
    // width: 100%;
  }

  .m-auto-mobile {
    margin: unset !important;
  }
}

@media screen and (min-width: 320px) {
  .m-auto-mobile {
    margin: auto;
  }
}

::ng-deep ngx-intl-tel-input .iti {
  width: 100% !important;
}

ion-textarea {
  ::-webkit-scrollbar {
    display: none;
  }
}

.iti {
  width: 100% !important;
}

.text_overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.my-modal .modal-content {
  max-width: 402px !important;
  margin: auto;
}

.cursor {
  cursor: pointer;
}

.cursor-no-drop {
  cursor: no-drop;
}

.my-custom-class .popover-content {
  // transform-origin: left top !important;
  --background: var(--ion-cc-contrast-light);
  --width: 100% !important;
  --height: fit-content !important;
  --max-width: 347px !important;
  // bottom: 84px !important;
  // right: 7% !important;
  // left: unset !important;
  // top: unset !important;
}

.my-custom-class1 .popoverContent {
  background: var(--ion-cc-primary) !important;
  color: var(--ion-cc-contrast-light);
  min-width: 50%;
  height: fit-content !important;

  padding: 5px 5px 10px 5px;
  // background-color: blue;
}

.test_overflow {
  // text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.padding-bottom50 {
  padding-bottom: 50px;
}

//Settings generic css

.card_btn {
  span {
    text-align: left;
    width: 90%;
  }

  --border-radius: 10px;
  height: 85px;
  // width: 100%;
  white-space: pre-wrap;
  // margin-bottom: 5px;
}

.card_header {
  height: 150px;
  background-color: var(--ion-cc-background-grey) !important;
}

.card_header_2 {
  height: 93px;
  background-color: var(--ion-cc-background-grey) !important;
}

.card_content_color {
  --background: var(--ion-cc-background-grey) !important;
}

.card_content_color_2 {
  --background: #d8d8d8 !important;
}

.card_tool_top_pading {
  padding-top: 20px !important;
}

.card_content_scroll {
  overflow: scroll;
  height: 100%;
  // padding-bottom: 25px;
}

.card_icon {
  height: 30px;
  width: 30px;
}

.card_icon_lg {
  height: 104px;
  width: 104px;
}

.card_header_3 {
  height: 200px;
  background-color: #d8d8d8 !important;

  .ion-back-button-black {
    margin-top: -120px;
  }

  p {
    padding-top: 60px;
  }
}

.content-style-1 {
  background-color: var(--ion-cc-background-grey) !important;

  .curveddiv {
    border-radius: 40px 40px 0 0;
    padding-top: 70px;
    position: sticky;
    top: 0;
    box-shadow: 0px -40px #d8d8d8;
  }

  .contentgrid {
    bottom: 0px;
    background-color: var(--ion-cc-background-grey) !important;
    margin-top: -50px;
    padding-left: 12%;
    padding-right: 12%;
  }

  .title {
    color: #080f18;
    font-weight: bold;
    font-size: 24px;
    font-family: Poppins;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 0px;
  }

  .termscontent {
    color: var(--ion-cc-contrast-dark) !important;
    opacity: 60%;
    font-weight: bold;
    font-size: 14px;
    font-family: Poppins;
    margin-bottom: 0;
  }

  .center {
    text-align: center;
  }

  .right {
    text-align: right;
  }

  .buttons {
    margin-top: 100px;
    border-radius: 20px;
    text-align: center;
    background: linear-gradient(to right, #0b59b0, #1272e3);
    box-shadow: 0 0 20px #00000080;
    position: relative;
    height: 120px;
  }

  .contentrow {
    padding-top: 30px;
  }

  .acceptcol {
    padding-left: 10%;
  }

  .rejectcol {
    padding-right: 10%;
  }

  .buttonicon {
    height: 27px;
    width: 27px;
    color: var(--ion-cc-primary-tint);
    background-color: var(--ion-cc-secondary-tint);
    border-radius: 50%;
  }

  .buttonicondone {
    height: 27px;
    width: 27px;
    color: var(--ion-cc-primary-tint);
    background-color: #3cadfc;
    border-radius: 50%;
  }

  .dashedline {
    border-left: 1px dashed var(--ion-cc-primary-grey);
    height: 90px;
    margin-top: -20px;
    margin-left: 50%;
  }

  .buttontext {
    color: var(--ion-cc-contrast-light) !important;
    font-size: 20px;
    font-family: Poppins;
  }

  .buttontextdone {
    color: #3cadff;
    font-size: 20px;
    font-family: Poppins;
  }
}

.card-content {
  position: relative;
  background-color: var(--ion-cc-contrast-light) !important;
  border-top-right-radius: 35px !important;
  border-top-left-radius: 35px !important;
  height: 100%;
  box-shadow: 0px 0px 8px #b3c6cf80;

  // margin-top: 10px;
  .card-content-scroll-Profile {
    overflow: scroll;
    height: calc(100vh - 285px);
    padding: 20px;
  }

  .clickable-text {
    color: var(--ion-cc-primary);
    margin-top: 5px;
  }
}

.card-content-scroll {
  overflow: scroll;
  height: 100%;
}

.card_content_radius {
  background-color: var(--ion-cc-contrast-light) !important;
  border-top-right-radius: 25px !important;
  border-top-left-radius: 25px !important;
  height: 100%;
  box-shadow: 0px 0px 8px #b3c6cf80;
  margin-top: 3px;
}

// mobile header //
.header {
  --color: var(--ion-cc-contrast-light) !important;
  --background: var(--ion-cc-primary) !important;
  padding: 10px !important;

  ion-title {
    padding-right: 14px;
    line-height: 1.25em;
  }
}

.catHeader {
  border-bottom: 2px solid #4cda63;
}

.ion_background1 {
  background: #0853a8 !important;
}

// .text-blue {
//   color: #0853a8 !important;
// }

.ion_content_background_white {
  background: var(--ion-cc-contrast-light) !important;
}

// Form related css

//mobile
.ion_item_black {
  --border-color: var(--ion-cc-contrast-dark) !important;
}

.ion_lable_font {
  font-size: 20px !important;
}

.footer_bg_white {
  background-color: var(--ion-cc-contrast-light) !important;
}

.two-lines-ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.viewall-icon {
  position: relative;
  top: 2px;
}

.gallery-list {
  margin-top: 25px;
  padding-bottom: 20px;
  overflow: scroll;
  height: calc(100vh - 425px);

  .btn_cls_sm {
    margin-right: 10px;
    margin-bottom: 25px;
  }

  .gallery-list-img {
    height: 70px;
    width: 70px;
    margin-right: 10px;
    margin-bottom: 25px;
    --padding-start: 0;
    --padding-end: 0;
    --background: var(--ion-cc-contrast-light) !important;

    img {
      width: 100%;
      max-height: 70px;
    }

    .video-play-icon {
      position: absolute;
      height: 50%;
    }
  }

  .active-img {
    --border-color: var(--ion-cc-primary) !important;
    --border-width: 5px;
    --border-style: solid;
  }

  .video {
    .list-img {
      opacity: 0.5;
    }
  }
}

.upload-image-fab-button {
  position: relative;
  height: fit-content;
  margin-top: 4px;

  .add-close-btn {
    height: 70px;
    width: 70px;
    --background: var(--ion-cc-item-grey) !important;
    --color: var(--ion-cc-primary-tint) !important;
    --border-radius: 5px;
    --box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
      0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    --close-icon-font-size: 50px;

    .puls-icon {
      font-size: 50px;
    }
  }

  ion-fab-list {
    background: var(--ion-cc-contrast-light) !important;
    padding: 16px;
    bottom: 0;
    left: -11px;
    margin-bottom: 0;
    padding-bottom: 80px;
    z-index: -1;

    ion-fab-button {
      margin-bottom: 20px;
      height: 60px;
      width: 60px;

      ion-icon {
        font-size: 25px;
      }
    }
  }
}

ion-checkbox {
  --background: var(--ion-cc-contrast-light) !important;
  --border-color: var(--ion-cc-contrast-dark) !important;
  --background-checked: var(--ion-cc-contrast-light);
  --checkmark-color: var(--ion-cc-contrast-dark);
}

ion-searchbar {
  // color: var(--ion-cc-contrast-dark) !important;
  --placeholder-color: var(--ion-cc-primary-grey-tint) !important;
  --color: var(--ion-cc-contrast-dark) !important;
  --placeholder-opacity: 0.8 !important;
}

.padding-bottom-15 {
  padding-bottom: 15px !important;
}

.padding-top-15 {
  padding-top: 15px;
}

.padding-bottom-6 {
  padding-bottom: 6pt !important;
}

.card-board-bottom {
  border-bottom: solid #c5c5c5 1px;
  padding-bottom: 5px;
}

.width {
  width: fit-content;
}

.text-left {
  text-align: left;
}

.btn_style_4 {
  --color: var(--ion-cc-primary-grey-tint) !important;
  --background: var(--ion-customcolor-light) !important;
  padding-left: 98px;
}

.white-space-normal {
  white-space: normal;
}

.white-space-nowrap {
  white-space: nowrap;
}

//to override ion-datetime picker color
.picker-prefix,
.picker-suffix,
.picker-opt.picker-opt-selected {
  color: var(--ion-cc-primary);
}

.picker-button.sc-ion-picker-md,
.picker-button.ion-activated.sc-ion-picker-md {
  color: var(--ion-cc-primary);
}

.width-fit-content {
  width: fit-content;
  margin: auto;
}

.bg-w-lg {
  background: #f5f8fa 0% 0% no-repeat padding-box;
}

// Stepper
.stepper {
  .step {
    // position: relative;
    // min-height: 1em;
    color: var(--ion-cc-primary-grey-shade) !important;
  }

  .caption {
    font-size: 0.8em;
  }

  .step + .step {
    margin-top: 20px;
  }

  .step > div:first-child {
    position: static;
    height: 0;
  }

  .step > div:not(:first-child) {
    margin-left: 1.5em;
    padding-left: 1em;
  }

  .step {
    .circle-active {
      border: 4px solid var(--ion-cc-primary) !important;
      width: 27px !important;
      height: 26px !important;
      border-radius: 100%;
      position: relative;
      right: 0 !important;

      div {
        height: 12px;
        width: 12px;
        background-color: var(--ion-cc-primary);
        border-radius: 50%;
        position: absolute;
        bottom: 3px;
        left: 3.5px;
      }
    }

    .green {
      background-color: var(--ion-cc-secondary-tint);
      border: 1px solid var(--ion-cc-secondary-tint) !important;
    }

    // .stepper .step .green-line {
    //   .div {
    //     border-left: 3px solid #20CD98 !important;
    //   }

    // }

    /* Circle */
    .circle {
      // background: gray;
      position: relative;
      right: 6px;
      width: 18px;
      height: 18px;
      line-height: 1.5em;
      border-radius: 100%;
      color: var(--ion-cc-contrast-light);
      text-align: center;
      border: 3px solid #4b4b4b4d;
      // box-shadow: 0 0 0 3px #fff;
    }

    .line {
      position: relative;
      height: -webkit-fill-available;
      min-height: 40px;
      top: 10px;
      margin-bottom: 10px;

      div {
        border-left: 3px solid #4b4b4b29;
        height: 100%;
        position: absolute;
        right: 13px;
      }

      .green-line {
        border-left: 3px solid var(--ion-cc-secondary-tint) !important;
      }
    }
  }
}

.gutter-space {
  max-width: 768px;
  margin: auto;
  width: 100%;
}

.gutter-space-large {
  max-width: 1500px;
  margin: auto;
  width: 100%;
}

.display-none {
  display: none !important;
}

ion-back-button.ios {
  margin-right: 10px;
}

@media screen and (min-width: 576px) {
  .widget-template {
    transition: width 0.3s;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  }
}

.help1 {
  font-size: 28px;
  color: var(--ion-cc-primary);
  border-radius: 50px;
  background: #d0dfef;
  position: relative;
  bottom: 6px;
  float: right;
  z-index: 1;
}

.help-dropdown {
  transform: translate3d(246px, 20px, 0px) !important;
  height: 27% !important;
  border-radius: 5px !important;
  min-width: 130px !important;
  width: 35% !important;
  // padding: 8px;

  .dropdown-item {
    color: var(--ion-cc-primary-grey) !important;
  }

  .dropdown-item:active {
    color: var(--ion-cc-primary) !important;
    background-color: transparent !important;
  }

  .dropdown-item:hover {
    color: var(--ion-cc-primary) !important;
  }

  .close-help {
    display: none;
  }
}

@media screen and (max-width: 576px) {
  .help-dropdown {
    transform: translate3d(0px, -8px, 0px) !important;
    height: 100% !important;
    border-radius: 25px !important;
    // width: 100% !important;
    min-width: 100% !important;
    padding: 8px;

    .close-help {
      font-size: 20px;
      margin: 8px !important;
      float: right;
      display: block !important;
    }
  }

  .font-10 {
    font-size: 10px !important;
  }

  .font-12 {
    font-size: 12px !important;
  }

  .font-14 {
    font-size: 14px !important;
  }

  .font-16 {
    font-size: 16px !important;
  }

  .font-18 {
    font-size: 18px !important;
  }

  .font-20 {
    font-size: 20px !important;
  }

  .font-24 {
    font-size: 24px !important;
  }

  .font-26 {
    font-size: 26px !important;
  }

  .font-28 {
    font-size: 28px !important;
  }

  .font-30 {
    font-size: 30px !important;
  }
}

.widget-template {
  // transition: width 0.5s;
  // transition-timing-function: cubic-bezier(0.4,0,0.2,1);
  // height: 400px;
  border-radius: 25px;
  border: none;
  box-shadow: none;
  // width: 31vw;
  // overflow: auto;
  // min-width: 379px;
  width: 379px;
  min-height: 437px;
  border: 0.1px solid var(--ion-cc-contrast-light);
  margin-bottom: 20px;
  margin-right: 55px;
}

.widget-template:hover {
  // border: 0.1px solid var(--ion-cc-primary);
  box-shadow: inset 0px 3px 6px #0b58b04c;
}

// CSS Template for Content Container
.content-container {
  border-radius: 18px;
  background: var(--ion-cc-background-grey-light);
  box-shadow: 0px 1px 8px #0b58b029;
  margin: 5px 25px;
}

ion-button[disabled] {
  opacity: 0.7;
}

// Default Border for all ion-item
.input-border {
  border: 1px solid var(--ion-cc-border-primary-light);
  border-radius: 5px;
  background: var(--ion-cc-contrast-light);
}

// Border Color for all ion-item on Hover/ when Focused
.input-border:hover,
ion-item.input-border:focus-within {
  border: 1px solid var(--ion-cc-primary);
}

// To Animate the Button or Close Button when Clicked
.button-animation:active {
  animation: clickAnimation 0.1s;
}

@keyframes clickAnimation {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(0.9);
  }

  100% {
    transform: scale(1);
  }
}

// To Animate the Button when it is Disabled
.button-animation[disabled]:active {
  animation: shakeAnimation 0.3s;
}

@keyframes shakeAnimation {
  0% {
    transform: translateX(0);
  }

  25% {
    transform: translateX(-10px);
  }

  50% {
    transform: translateX(10px);
  }

  75% {
    transform: translateX(-10px);
  }

  100% {
    transform: translateX(0);
  }
}

ion-item {
  --highlight-height: 0;
}

.scroll {
  overflow-y: auto;
  scroll-behavior: smooth;
}

.scroll::-webkit-scrollbar {
  width: 0.4em;
}

.scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 20px;
}

.scroll::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  // outline: 1px solid slategrey;
  border-radius: 20px;
}

@media screen and (min-width: 992px) {
  .onboarding-bg {
    background: var(--ion-cc-contrast-light) !important;
    border-radius: 15px;
    padding-bottom: 20px;
    padding-top: 20px;
    padding-left: 22px;
    padding-right: 20px;
    box-shadow: 0 3px 6px #00000029;
  }
}

.onboarding-bg {
  .onboard-content-section {
    padding-bottom: 72px;
  }
}

.card-height {
  min-height: 0;
  height: 100%;
}

.footer-web {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: 1;
  background-color: var(--ion-cc-contrast-light) !important;
  border-radius: 15px;
}

.bg-transparent {
  background: transparent;
}

.header-style-1 {
  background-color: var(--ion-cc-contrast-light) !important;
  box-shadow: 0px 3px 13px #0b58b029;
  position: relative;
  z-index: 1;

  ul {
    li {
      list-style-type: none;

      a:hover {
        font-weight: bold;
        color: #0b58b0 !important;
      }
    }
  }
}

// fo
// fo icon color
.iconColor {
  color: var(--ion-cc-primary-grey-tint) !important;
}

// .padding {
//   padding-left: 60px;
//   padding-right: 90px;
//   padding-bottom: 40px;
// }

.padding-right-50 {
  padding-right: 50px;
}

.padding-top-5 {
  padding-top: 5px;
}

.padding-top-40 {
  padding-top: 40px;
}

.padding-top-13 {
  padding-top: 13px;
}

.padding-top-50 {
  padding-top: 50px;
}

.opacity-60 {
  opacity: 60%;
}

.text-shadow {
  text-shadow: 1.5px 1.5px #000000;
}

.text-white {
  color: var(--ion-cc-contrast-light) !important;
}

.text-black {
  color: var(--ion-cc-contrast-dark) !important;
}

.text-light-gray {
  color: var(--ion-cc-primary-grey-tint) !important;
  //#707070
}

.textgray {
  color: var(--ion-cc-primary-grey-shade) !important;
  //#4b4b4b
}

.text-primary-gray {
  color: var(--ion-cc-primary-grey) !important;
  //#8d8d8d
}

.text_left {
  text-align: left;
  display: -webkit-inline-box;
  padding-left: 30px;
}

.proApp {
  background: var(--ion-cc-primary);
}

.proContent {
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
}

.forward-btn {
  font-size: 30px;
}

.w-60 {
  width: 60%;
}

.tooltip-style-1 {
  min-width: 140px;
  max-width: 320px;

  .tooltip-inner {
    background: white;
    border-radius: 10px;
    border: 1px solid var(--ion-cc-primary);
    // color: var(--primary-black);
    // font-size: var(--size-14);
    // font-weight: var(--fw-600);
    text-align: left;
  }
}

@media only screen and (max-width: 992px) {
  .ios .space-top {
    padding-top: 35px !important;
  }

  @media only screen and (max-width: 992px) {
    .ios .space-top-schedule-details {
      margin-top: 35px !important;
    }
  }
}

.button-grey {
  background: var(--ion-cc-background-grey);
  // background: red;
}

.item-grey {
  // background: var(--ion-cc-item-grey);
  background: var(--ion-cc-background-grey);
}

.item-border-radius {
  border-radius: 10px;
}

.border-black {
  // border: 2px solid black;
}

.line-clamp-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.line-clamp-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.line-clamp-4 {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.line-clamp-5 {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.height-75 {
  height: 75vh;
}

ion-searchbar ion-icon {
  right: 16px !important;
  left: auto !important;
  // padding-inline-start: 23px !important;
}

.searchbar-input.sc-ion-searchbar-md {
  padding-inline-start: 16px !important;
}

@media only screen and (max-width: 576px) {
  .searchbar-input.sc-ion-searchbar-ios {
    font-size: 14px !important;
    padding-left: 15px !important;
    // border: 2px solid black;
  }
}

ion-searchbar.searchbar-has-value .searchbar-search-icon {
  right: 35px !important;
  left: auto !important;
}

.background-grey {
  background: var(--ion-cc-background-grey);
}

.background-primary {
  background: var(--ion-cc-primary);
}

.logo,
.dp-style-1 {
  width: 45px;
  height: 45px;
}

.project-logo,
.dp-style-2 {
  width: 55px;
  height: 55px;
}

.dp-style-3 {
  border: 1px solid var(--ion-cc-background-grey);
  width: 105px;
  height: 105px;
}

.project-logo-mini {
  width: 30px;
  height: 30px;
}

.border-50 {
  border-radius: 50%;
}

.close-screen {
  width: 30px;
  height: 30px;
}

.mobile-header {
  background: var(--ion-cc-contrast-light) !important;
}

@media only screen and (max-width: 768px) {
  .background-grey {
    background: var(--ion-cc-contrast-light) !important;
  }
}

@media only screen and (min-width: 769px) {
  .proApp {
    background: var(--ion-cc-background-grey);
  }

  .proContent {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }
}
@media only screen and (min-width: 992px) {
  ion-app {
    background: var(--ion-cc-background-grey);
  }
}

p-dialog .p-dialog .p-dialog-content {
  padding: 20px;
  // padding-bottom: 20px;
  border-bottom-right-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}

p-dialog .p-dialog .p-dialog-header {
  border-top-right-radius: 10px !important;
  border-top-left-radius: 10px !important;
}

@media only screen and (max-width: 576px) {
  p-dialog .p-dialog .p-dialog-content,
  p-dialog .p-dialog .p-dialog-header {
    padding: 10px !important;
  }
}

.checkbox-default {
  --border-radius: 5px;
  --border-color: var(--ion-cc-primary-grey) !important;
  --border-color-checked: var(--ion-cc-primary);
  --background-checked: var(--ion-cc-primary);
  --checkmark-color: var(--ion-cc-contrast-light);
  --size: 20px;
}

.radio-default {
  --color-checked: var(--ion-cc-primary-tint);
}

.pre-line {
  white-space: pre-line;
}

.message-height {
  height: 150px;
}

// ::ng-deep{

.cancel {
  color: var(--ion-cc-primary) !important;
}

.confirm-delete {
  color: var(--ion-cc-tertiary-tint) !important;
}
// }
